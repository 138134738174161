import { useTranslation } from 'react-i18next';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import mixpanelTrack from '~/hooks/useMixPanel';
import { Button } from '~/components/common/buttons/Button';
import LoaderButton from '~/components/common/buttons/LoaderButton';
import TypeFormLinkContainer from '~/components/common/buttons/TypeFormLinkContainer';

const HeroSectionB = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="row g-6">
          <div className="order-sm-1 col-12 col-sm-6">
            <LoaderButton
              btnText={t('landing.hero.buttons.seeOurOffices.label')}
              variant="accent"
              size="xl"
              extraClasses="w-100"
              iconType="search"
              to={t('landing.hero.buttons.seeOurOffices.linkPath')}
              onClick={() => {
                mixpanelTrack('0.see_offices_hero');
              }}
            />
          </div>
          <div className="col-12 col-sm-6">
            <TypeFormLinkContainer
              link={t('landing.hero.buttons.entrustMySearch.linkPath')}
              onClick={() => mixpanelTrack('0.search_help_hero')}
            >
              <Button
                extraClasses="w-100"
                variant="accentOutline"
                size="xl"
              >
                <span className="me-2">{t('landing.hero.buttons.weFindForYou.label')}</span>
                <SVGIcon iconType="long-arrow-up-right" />
              </Button>
            </TypeFormLinkContainer>
          </div>
        </div>

        <div className="d-flex flex-wrap gap-7 justify-content-center mt-6 mt-md-10">
          <div className="bullet-point d-flex align-items-center">
            <img
              className="me-4"
              src="/media-remix/icons/hero-seats.svg"
              alt="Seats icon"
            />
            <p className="text-2 -medium text-nowrap">{t('landing.hero.bullet1')}</p>
          </div>

          <div className="bullet-point d-flex align-items-center">
            <img
              className="me-4"
              src="/media-remix/icons/hero-contract.svg"
              alt="Contract icon"
            />
            <p className="text-2 -medium text-nowrap">{t('landing.hero.bullet2')}</p>
          </div>

          <div className="bullet-point d-flex align-items-center">
            <img
              className="me-4"
              src="/media-remix/icons/hero-commission.svg"
              alt="Commission icon"
            />
            <p className="text-2 -medium text-nowrap">{t('landing.hero.bullet3')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionB;
